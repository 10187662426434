@charset "UTF-8";
/* Reset
------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
legend,
figure,
figcaption,
button,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

address,
em {
  font-style: normal;
}

th {
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th {
  text-align: left;
}

main,
aside {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

img,
fieldset {
  border: 0;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
  object-fit: contain;
  vertical-align: top;
}

li {
  list-style-type: none;
}

select,
button {
  appearance: none;
}

input[type=submit],
button,
label,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

br {
  line-height: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium"), local("YuGothic-Medium");
}
@font-face {
  font-family: "Yu Gothic";
  font-weight: bold;
  src: local("Yu Gothic Bold"), local("YuGothic-Bold");
}
/* link
------------------------------------------------------------*/
a {
  color: #3c3c3c;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}

a:hover,
button:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 992px) {
  a:hover,
button:hover {
    opacity: 1;
  }
}

/* Fonts
------------------------------------------------------------*/
html {
  transition: font-size 0.3s;
}
html[data-font=small] {
  font-size: 12px;
}
@media only screen and (max-width: 992px) {
  html[data-font=small] {
    font-size: 16px;
  }
}
html[data-font=large] {
  font-size: 18px;
}
@media only screen and (max-width: 992px) {
  html[data-font=large] {
    font-size: 16px;
  }
}

body {
  color: #3c3c3c;
  line-height: 1.6;
  overflow-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  font-family: "メイリオ", Meiryo, -apple-system, BlinkMacSystemFont, "Hiragino Kaku Gothic ProN", sans-serif;
  font-weight: 500;
  font-size: 1rem;
}
@media only screen and (max-width: 768px) {
  body {
    font-size: 0.8125rem;
  }
}
body.noScroll {
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  body.noScroll {
    height: 100vh;
    left: 0;
    position: fixed;
    width: 100%;
  }
}

input,
button,
textarea,
select {
  color: #3c3c3c;
  font-family: "メイリオ", Meiryo, -apple-system, BlinkMacSystemFont, "Hiragino Kaku Gothic ProN", sans-serif;
  line-height: 1.6;
}

#root {
  overflow: hidden;
}

.l-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media only screen and (max-width: 992px) {
  .l-header {
    padding-top: 66px;
  }
}

.l-header-main {
  background-image: url("../img/common/bg_header_line.png");
  background-repeat: repeat-x;
  background-size: auto 6px;
  background-position: top center;
  font-family: "M PLUS 1p", sans-serif;
  padding-bottom: 25px;
  padding-top: 6px;
  position: relative;
  z-index: 3;
}
@media only screen and (max-width: 992px) {
  .l-header-main {
    background-color: #fff;
    left: 0;
    padding: 6px 0 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
}

.l-header-main__container {
  margin: 0 auto;
  max-width: 1530px;
  padding: 0 15px;
}

.l-header-main__inner {
  position: relative;
}

.l-header-logo {
  align-items: center;
  display: flex;
  height: 100px;
}
@media only screen and (max-width: 992px) {
  .l-header-logo {
    height: 60px;
    width: 157px;
  }
}

@media only screen and (max-width: 992px) {
  .l-nav {
    background-color: #fff;
    height: calc(100% - 60px);
    left: 0;
    opacity: 0;
    overflow: auto;
    position: fixed;
    top: 60px;
    visibility: hidden;
    width: 100%;
  }
  .l-nav[aria-hidden=false] {
    opacity: 1;
    visibility: visible;
  }
}

.l-nav-main {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 992px) {
  .l-nav-main {
    display: block;
  }
}

.l-nav-main__item {
  flex: 1;
  position: relative;
}
@media only screen and (max-width: 992px) {
  .l-nav-main__item {
    border-top: 1px solid #c5c5c6;
    width: 100%;
  }
}
.l-nav-main__item::after {
  background-image: url("../img/common/bg_nav_line.svg");
  background-repeat: no-repeat;
  background-position: center;
  content: "";
  height: 65px;
  position: absolute;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
}

@media only screen and (max-width: 992px) {
  .l-nav-main__item:last-child {
    border-bottom: 1px solid #c5c5c6;
  }
}
.l-nav-main__item:last-child::after {
  display: none;
}

.l-nav-main__item--spOnly {
  display: none;
}
@media only screen and (max-width: 768px) {
  .l-nav-main__item--spOnly {
    display: block;
  }
}

.l-nav-main__target {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  text-align: center;
  text-decoration: none;
}

@media only screen and (max-width: 992px) {
  .l-nav-main__target--toggle {
    position: relative;
  }
  .l-nav-main__target--toggle::after {
    background-image: url("../img/common/icon_plus_nav.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    content: "";
    height: 10px;
    position: absolute;
    right: 13px;
    top: calc(50% - 5px);
    width: 10px;
  }
  .l-nav-main__target--toggle[aria-expanded=true]::after {
    background-image: url("../img/common/icon_minus_nav.svg");
  }
}

.l-nav-main__icon {
  margin-bottom: 5px;
  width: 100%;
}
@media only screen and (max-width: 992px) {
  .l-nav-main__icon {
    display: none;
  }
}

.l-nav-main__text {
  font-size: 1.125rem;
  line-height: 1.5;
  width: 100%;
}
@media only screen and (max-width: 1200px) {
  .l-nav-main__text {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 992px) {
  .l-nav-main__text {
    font-size: 0.9375rem;
    text-align: left;
    padding: 15px 20px;
  }
  .l-nav-main__text br {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .l-nav-main__target:not(.l-nav-main__target--toggle) .l-nav-main__text::after {
    background-image: url("../img/common/icon_arrow_nav.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 10px;
    right: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
  }
}

.l-nav-main-child {
  background-color: #fff;
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  transition: height 0.3s linear, opacity 0.3s linear, visibility 0.3s linear;
  visibility: hidden;
  width: 100%;
}
@media only screen and (max-width: 992px) {
  .l-nav-main-child {
    position: relative;
  }
  .l-nav-main-child[aria-hidden=false] {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
}

.l-nav-main-child__inner {
  padding: 20px 10px;
}
@media only screen and (max-width: 992px) {
  .l-nav-main-child__inner {
    padding: 0 0 15px 45px;
  }
}

.l-nav-main-child__item {
  font-size: 0.875rem;
}
@media only screen and (max-width: 992px) {
  .l-nav-main-child__item {
    font-size: 0.9375rem;
  }
}

.l-nav-main-child__item:nth-child(n+2) {
  margin-top: 15px;
}

.l-nav-main-child__target {
  text-decoration: none;
}

@media only screen and (min-width: 993px) {
  .l-nav-main__item--accordion:hover .l-nav-main-child, .l-nav-main__item--accordion:focus-within .l-nav-main-child {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
}

.l-nav-sub {
  align-items: center;
  display: flex;
  height: 100px;
  position: absolute;
  right: 0;
  top: 0;
}
@media only screen and (max-width: 992px) {
  .l-nav-sub {
    display: block;
    height: auto;
    padding: 25px 0;
    position: relative;
  }
}

.l-nav-sub__item a {
  text-decoration: none;
}

.l-nav-sub__item:nth-child(n+2) {
  margin-left: 25px;
}
@media only screen and (max-width: 992px) {
  .l-nav-sub__item:nth-child(n+2) {
    margin-left: 0;
    margin-top: 15px;
  }
}

.l-nav-sub__target {
  align-items: center;
  display: flex;
  text-decoration: none;
}
@media only screen and (max-width: 992px) {
  .l-nav-sub__target {
    border: 2px solid #ea6d9f;
    border-radius: 100px;
    margin: 0 auto;
    padding: 7.5px 15px;
    width: 255px;
  }
}

.l-nav-sub__icon {
  width: 51px;
}
@media only screen and (max-width: 992px) {
  .l-nav-sub__icon {
    width: 44px;
  }
}

.l-nav-sub__text {
  font-size: 1.125rem;
  line-height: 1;
  padding-left: 15px;
  width: calc(100% - 51px);
}
@media only screen and (max-width: 992px) {
  .l-nav-sub__text {
    font-size: 1rem;
    padding-left: 10px;
  }
}

.l-nav-sub__tel-number {
  color: #ea6d9f;
  display: block;
  font-size: 1.4375rem;
  font-weight: 700;
}
@media only screen and (max-width: 992px) {
  .l-nav-sub__tel-number {
    font-size: 1.25rem;
  }
}

.l-nav-sub__tel-caption {
  display: block;
  font-size: 0.875rem;
  margin-top: 2px;
}
@media only screen and (max-width: 992px) {
  .l-nav-sub__tel-caption {
    font-size: 0.75rem;
  }
}

.l-nav-toggle {
  appearance: none;
  background: none;
  border: none;
  display: none;
  height: 60px;
  position: fixed;
  right: 0;
  top: 0;
  width: 60px;
  z-index: 11;
}
@media only screen and (max-width: 992px) {
  .l-nav-toggle {
    display: block;
  }
}

.l-nav-toggle__bar {
  background: #ea6d9f;
  height: 2px;
  left: 18.5px;
  position: absolute;
  top: calc(50% + 5px);
  transition: all 0.3s linear;
  width: 25px;
}
.l-nav-toggle__bar:nth-child(1) {
  margin-top: -10px;
}
@media only screen and (max-width: 992px) {
  .l-nav-toggle__bar:nth-child(1) {
    margin-top: -8px;
  }
}
.l-nav-toggle[aria-expanded=true] .l-nav-toggle__bar:nth-child(1) {
  margin-top: 0;
  transform: rotate(45deg);
}

.l-nav-toggle[aria-expanded=true] .l-nav-toggle__bar:nth-child(2) {
  left: 30px;
  opacity: 0;
}

.l-nav-toggle__bar:nth-child(3) {
  margin-top: 10px;
}
@media only screen and (max-width: 992px) {
  .l-nav-toggle__bar:nth-child(3) {
    margin-top: 8px;
  }
}
.l-nav-toggle[aria-expanded=true] .l-nav-toggle__bar:nth-child(3) {
  margin-top: 0;
  transform: rotate(-45deg);
}

.l-footer-pageTop {
  border-radius: 100px;
  box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.1);
  bottom: 16px;
  overflow: hidden;
  position: fixed;
  right: 16px;
  transition: opacity 0.3s linear, visibility 0.3s linear;
  z-index: 2;
}
@media only screen and (max-width: 992px) {
  .l-footer-pageTop {
    width: 50px;
  }
}

.l-footer-pageTop[aria-hidden=true] {
  opacity: 0;
  visibility: hidden;
}

.l-footer-main {
  background-image: url("../img/common/bg_footer.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 60px 0 115px;
}
@media only screen and (max-width: 992px) {
  .l-footer-main {
    padding: 25px 0;
  }
}

.l-footer-main__inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.l-footer-main__item {
  width: 50%;
}
@media only screen and (max-width: 992px) {
  .l-footer-main__item {
    width: 100%;
  }
}

.l-footer-main-fb {
  text-align: right;
}
@media only screen and (max-width: 992px) {
  .l-footer-main-fb {
    text-align: center;
  }
}

.l-footer-main-list {
  display: flex;
  font-size: 1rem;
}
@media only screen and (max-width: 992px) {
  .l-footer-main-list {
    display: none;
  }
}

.l-footer-main-list__block {
  width: 270px;
}

.l-footer-main-list__item {
  position: relative;
}
.l-footer-main-list__item::before {
  content: "＞";
  display: inline-block;
  width: 1em;
}

.l-footer-main-list__item:nth-child(n+2) {
  margin-top: 15px;
}

.l-footer-main-list__target {
  line-height: 1.2;
  text-decoration: none;
}

.l-footer-main-list-child {
  padding: 15px 0 0 20px;
}

.l-footer-main-copyright {
  line-height: 1.2;
  margin-top: 50px;
}
@media only screen and (max-width: 992px) {
  .l-footer-main-copyright {
    margin-top: 25px;
    text-align: center;
  }
}

@media only screen and (min-width: 1201px) {
  .l-contents {
    flex: 1 0 auto;
  }
}

/*
c-block
*/
.c-block-entry {
  background-image: url("../img/common/bg_entry_pc.jpg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  padding: 75px 0;
  position: relative;
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .c-block-entry {
    background-image: url("../img/common/bg_entry_sp.jpg");
    padding: 30px 0;
  }
}
.c-block-entry::before, .c-block-entry::after {
  background-image: url("../img/common/bg_header_line.png");
  background-repeat: repeat-x;
  background-size: auto 6px;
  background-position: top center;
  content: "";
  height: 6px;
  left: 0;
  position: absolute;
  width: 100%;
}
.c-block-entry::before {
  top: 0;
}
.c-block-entry::after {
  bottom: 0;
}

.c-block-entry__inner {
  padding: 0 320px;
  position: relative;
}
@media only screen and (max-width: 992px) {
  .c-block-entry__inner {
    padding: 0;
  }
}

.c-block-entry-catch {
  font-family: "M PLUS 1p", sans-serif;
  font-size: 1.25rem;
  letter-spacing: 0.1em;
  line-height: 1;
  margin-bottom: 10px;
}
@media only screen and (max-width: 992px) {
  .c-block-entry-catch {
    font-size: 0.75rem;
    margin-bottom: 5px;
  }
}

.c-block-entry-catch__inner {
  display: inline-block;
  padding: 0 15px;
  position: relative;
}
.c-block-entry-catch__inner::before, .c-block-entry-catch__inner::after {
  background: currentColor;
  border-radius: 100px;
  content: "";
  height: 25px;
  position: absolute;
  top: 50%;
  width: 2px;
}
.c-block-entry-catch__inner::before {
  left: 0;
  transform: rotate(-30deg) translateY(-50%);
}
.c-block-entry-catch__inner::after {
  right: 0;
  transform: rotate(30deg) translateY(-50%);
}

.c-block-entry-title {
  font-family: "M PLUS 1p", sans-serif;
  font-size: 2rem;
  letter-spacing: 0.1em;
  line-height: 1;
}
@media only screen and (max-width: 992px) {
  .c-block-entry-title {
    font-size: 1.875rem;
  }
}
@media only screen and (max-width: 600px) {
  .c-block-entry-title {
    font-size: 1.75rem;
  }
}

.c-block-entry-buttons {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 320px;
}
@media only screen and (max-width: 992px) {
  .c-block-entry-buttons {
    display: none;
  }
}

.c-block-entry-buttons__item:nth-child(n+2) {
  margin-top: 25px;
}

.c-block-entry__spLink {
  display: none;
}
@media only screen and (max-width: 992px) {
  .c-block-entry__spLink {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

/*
c-button
*/
.c-button1 {
  align-items: center;
  background-color: #ea6d9f;
  border-radius: 100px;
  color: #fff;
  display: inline-flex;
  font-size: 1.125rem;
  height: 53px;
  justify-content: center;
  line-height: 1.2;
  position: relative;
  text-decoration: none;
  width: 240px;
}
@media only screen and (max-width: 768px) {
  .c-button1 {
    font-size: 0.8125rem;
    height: 40px;
    width: 185px;
  }
}
.c-button1::after {
  background-image: url("../img/common/icon_button_arrow1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  content: "";
  height: 12px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
}
@media only screen and (max-width: 768px) {
  .c-button1::after {
    height: 9px;
    width: 6px;
  }
}

.c-button-link1 {
  font-size: 0.9375rem;
  padding-left: 15px;
  position: relative;
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
  .c-button-link1 {
    padding-left: 13px;
    font-size: 0.8125rem;
  }
}
.c-button-link1::before {
  background-image: url("../img/common/icon_button_arrow2.svg");
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 12px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}
@media only screen and (max-width: 768px) {
  .c-button-link1::before {
    height: 10px;
    width: 7px;
  }
}

/*
c-form
*/
/* form
------------------------------------------------------------*/
textarea,
input[type=text],
input[type=password],
input[type=email],
input[type=tel],
select {
  border: solid 1px #ababab;
  border-radius: 5px;
  padding: 10px;
}
textarea.error,
input[type=text].error,
input[type=password].error,
input[type=email].error,
input[type=tel].error,
select.error {
  background-color: #fff0f0;
  border: solid 2px #e43f42;
}

/* radio
-------------*/
input[type=radio] {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
input[type=radio] + .label {
  cursor: pointer;
  display: inline-block;
  padding: 3px 10px 3px 24px;
  position: relative;
  width: 100%;
}
input[type=radio] + .label::before, input[type=radio] + .label::after {
  border-radius: 100%;
  content: "";
  position: absolute;
  top: 50%;
  transition: all 0.2s;
}
input[type=radio] + .label::before {
  background: #ededed;
  border: 1px solid #cccccc;
  height: 16px;
  left: 0;
  margin-top: -9px;
  width: 16px;
}
input[type=radio] + .label::after {
  background: #3398db;
  height: 10px;
  left: 3px;
  margin-top: -6px;
  opacity: 0;
  transform: scale(0.5);
  width: 10px;
}
input[type=radio] + .label:hover::before {
  background: white;
}
input[type=radio].error + .label::before {
  background-color: #fff2f2;
  border: 1px solid #e43f42;
}
input[type=radio]:checked + .label::before {
  border: 1px solid #3398db;
}
input[type=radio]:checked + .label::after {
  opacity: 1;
  transform: scale(1);
}

/* checkbox
-------------*/
input[type=checkbox] {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
input[type=checkbox] + .label {
  cursor: pointer;
  display: inline-block;
  padding: 3px 10px 3px 22px;
  position: relative;
  transition: all 0.2s;
}
input[type=checkbox] + .label::before, input[type=checkbox] + .label::after {
  content: "";
  position: absolute;
}
input[type=checkbox] + .label::before {
  background: whitesmoke;
  border: 1px solid #cccccc;
  border-radius: 3px;
  height: 14px;
  left: 0;
  margin-top: -8px;
  top: 50%;
  width: 14px;
}
input[type=checkbox] + .label::after {
  border-bottom: 2px solid #3398db;
  border-left: 2px solid #3398db;
  height: 4px;
  left: 3px;
  margin-top: -4px;
  opacity: 0;
  top: 50%;
  transform: rotate(-45deg) scale(0.5);
  width: 8px;
}
input[type=checkbox]:checked + .label::before {
  border: 1px solid #3398db;
}
input[type=checkbox]:checked + .label::after {
  opacity: 1;
  transform: rotate(-45deg) scale(1);
}

/*
c-list
*/
.c-list-normal {
  margin: 0 0 0 2em;
}

ul.c-list-normal li:before {
  content: "・";
  display: inline-block;
  margin-left: -2em;
  text-align: center;
  width: 2em;
}

ol.c-list-normal li {
  list-style: decimal;
}

/* indent */
.c-list-indent01 li {
  margin: 0 0 0 1em;
  text-indent: -1em;
}

.c-list-indent02 li {
  margin: 0 0 0 2.5em;
  text-indent: -2.5em;
}

.c-dl-form {
  margin: 0 0 20px;
}
.c-dl-form dt {
  clear: both;
  float: left;
  padding: 33px 0 20px 63px;
  position: relative;
  width: 16em;
}
@media only screen and (max-width: 768px) {
  .c-dl-form dt {
    float: none;
    padding: 33px 0 0 63px;
    width: auto;
  }
}
.c-dl-form dt.required:before, .c-dl-form dt.any:before {
  left: 0;
  padding: 2px 10px;
  position: absolute;
  top: 33px;
  font-size: 0.6875rem;
}
.c-dl-form dt.required:before {
  background: #e43f42;
  content: "必須";
}
.c-dl-form dt.any:before {
  background: #999999;
  content: "任意";
}
.c-dl-form dd {
  border-bottom: solid 1px #cccccc;
  padding: 10px 0 10px 17em;
}
@media only screen and (max-width: 768px) {
  .c-dl-form dd {
    padding: 10px 0;
  }
}
.c-dl-form dd .parts {
  padding: 10px 0;
}
.c-dl-form dd .parts textarea,
.c-dl-form dd .parts input[type=text],
.c-dl-form dd .parts input[type=email] {
  width: 100%;
}
.c-dl-form dd .parts.radio_inline > div, .c-dl-form dd .parts.check_inline > div {
  display: inline-block;
}
.c-dl-form dd .parts.name {
  display: flex;
  justify-content: space-between;
}
.c-dl-form dd .parts.name > div {
  width: 48.5%;
}
.c-dl-form dd .parts.post, .c-dl-form dd .parts.tel {
  display: flex;
}
.c-dl-form dd .parts.post .hyphen, .c-dl-form dd .parts.tel .hyphen {
  padding: 10px 10px 0 10px;
}
@media only screen and (max-width: 600px) {
  .c-dl-form dd .parts.post .hyphen, .c-dl-form dd .parts.tel .hyphen {
    padding: 10px 5px 0 5px;
  }
}
.c-dl-form dd .parts.password input {
  max-width: 300px;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .c-dl-form dd .parts.password input {
    max-width: none;
  }
}
.c-dl-form dd .parts.password .text {
  padding: 10px 0;
}
.c-dl-form .validationError {
  color: #e43f42;
}

.c-list-breadcrumb-item {
  display: inline;
}
.c-list-breadcrumb-item:not(:last-child):after {
  content: ">";
  margin: 0 8px;
}
.c-list-breadcrumb-item.-home:before {
  display: none;
}
.c-list-news1 {
  font-size: 0.9375rem;
}
@media only screen and (max-width: 768px) {
  .c-list-news1 {
    font-size: 0.8125rem;
  }
}

.c-list-news1__item:nth-child(n+2) {
  margin-top: 25px;
}

.c-list-news1__target {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
}

.c-list-news1__date {
  width: 160px;
}
@media only screen and (max-width: 768px) {
  .c-list-news1__date {
    width: 115px;
  }
}

.c-list-news1__category {
  background-color: #ccc;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  padding: 5px 0;
  text-align: center;
  width: 70px;
}
@media only screen and (max-width: 768px) {
  .c-list-news1__category {
    padding: 4px 0;
    font-size: 0.625rem;
    width: 55px;
  }
}

.c-list-news1__category--category1 {
  background-color: #00b7de;
}

.c-list-news1__category--category2 {
  background-color: #66be96;
}

.c-list-news1__title {
  flex: 1;
  padding-left: 30px;
}
@media only screen and (max-width: 768px) {
  .c-list-news1__title {
    flex: auto;
    margin-top: 5px;
    padding-left: 0;
    width: 100%;
  }
}

.c-list-news1__new {
  color: #ea6d9f;
  font-size: 0.75rem;
  font-weight: 700;
}

/*
c-other
*/
@keyframes rotateLoading {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*↓消さないこと*/
.c-loading {
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.c-loading-inner {
  animation-delay: 0;
  animation-duration: 0.75s;
  animation-fill-mode: none;
  animation-iteration-count: infinite;
  animation-name: rotateLoading;
  animation-timing-function: linear;
  animation-fill-mode: both;
  border-radius: 50%;
  bottom: 0;
  box-shadow: 0 -10px 0 black, 7px -7px 0 #ededed, 10px 0 0 #dedede, 7px 7px 0 #cccccc, 0 10px 0 #bababa, -7px 7px 0 #ababab, -10px 0 0 #999999, -7px -7px 0 #666666;
  height: 5px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
}

@media only screen and (max-width: 768px) {
  .c-pc-only {
    display: none;
  }
}

.c-sp-only {
  display: none;
}
@media only screen and (max-width: 768px) {
  .c-sp-only {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  br.c-sp-only {
    display: inline;
  }
}

.c-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  padding-left: 15px;
  padding-right: 15px;
}

.c-parts {
  margin-bottom: 20px;
}

.c-parts-image {
  color: #949494;
  text-align: center;
  font-size: 0.6875rem;
}
.c-parts-image .caption {
  margin: 5px 0 0;
}
.c-parts-image a:hover {
  opacity: 0.8;
}

.c-parts-youtube {
  margin-left: auto;
  margin-right: auto;
  max-width: 560px;
}
@media only screen and (max-width: 768px) {
  .c-parts-youtube {
    padding-top: 56.25%;
    position: relative;
    width: 100%;
  }
  .c-parts-youtube iframe {
    height: 100% !important;
    position: absolute;
    right: 0;
    top: 0;
    width: 100% !important;
  }
}

.c-parts-text iframe {
  height: 400px;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .c-parts-text iframe {
    height: 250px;
  }
}
.c-parts-text ul {
  margin-left: 2em;
}
.c-parts-text ul li {
  list-style: disc;
}
.c-parts-text ol {
  margin-left: 2.2em;
}
.c-parts-text ol li {
  list-style: decimal;
}
.c-parts-text strong,
.c-parts-text th {
  font-weight: bold;
}
.c-parts-text .alignCenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.c-parts-text img.alignRight {
  display: inline;
  margin: 0 0 2px 7px;
  padding: 4px;
}
.c-parts-text img.alignLeft {
  display: inline;
  margin: 0 7px 2px 0;
  padding: 4px;
}
.c-parts-text .alignRight {
  float: right;
}
.c-parts-text .alignLeft {
  float: left;
}
.c-parts-text strong {
  font-weight: bold;
}

/*
c-Table
*/
/*
c-text
*/
/*
c-title
*/
.l-header-pageTitle__wrapper {
  margin-bottom: 50px;
  background-image: url("../img/common/bg_title.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 768px) {
  .l-header-pageTitle__wrapper {
    background-image: url("../img/common/bg_title_sp.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.l-header-pageTitle {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 1230px;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .l-header-pageTitle {
    height: 160px;
  }
}
.l-header-pageTitle h1 {
  font-size: 2rem;
  color: #3b3b3b;
}

/*
modContents
*/
#pankuzu {
  position: absolute;
  top: 10px;
  left: 0;
}
@media only screen and (max-width: 768px) {
  #pankuzu {
    display: none;
  }
}
#pankuzu ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
#pankuzu ul li {
  color: #3c3c3c;
}
#pankuzu ul li span,
#pankuzu ul li a {
  color: #0068B4;
  text-decoration: none;
  position: relative;
  margin-right: 25px;
}
#pankuzu ul li span:after,
#pankuzu ul li a:after {
  content: "";
  position: absolute;
  top: 6px;
  right: -15px;
  width: 9px;
  height: 9px;
  border-top: 1px solid #3c3c3c;
  border-right: 1px solid #3c3c3c;
  transform: rotate(45deg);
}

.wp-pagenavi {
  display: table;
  margin: 50px auto 20px;
  border-collapse: separate;
  border-spacing: 15px 0;
}
.wp-pagenavi a,
.wp-pagenavi span {
  font-weight: bold;
  height: 40px;
  width: 40px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 0 !important;
}
.wp-pagenavi a {
  border: 2px solid #dfdfdb !important;
}
.wp-pagenavi a:hover {
  border: 2px solid #0060ae !important;
  color: #fff;
  background: #0060ae;
  text-decoration: none;
}
.wp-pagenavi a.previouspostslink, .wp-pagenavi a.nextpostslink {
  color: #fff;
  border: 2px solid #9b9ea4 !important;
  background: #9b9ea4;
}
.wp-pagenavi span {
  border: 2px solid #0060ae !important;
  color: #fff;
  background: #0060ae;
}
.wp-pagenavi span.extend {
  color: #9b9ea4;
  background: none;
  border: none !important;
}

.modContentsContainer {
  padding: 5px 0 50px;
}

.modContents a {
  position: relative;
  color: #0068B4;
}
.modContents::after {
  content: "";
  display: table;
  table-layout: fixed;
  clear: both;
}
.modContents .alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
}
.modContents .alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
  margin-bottom: 1.5em;
}
.modContents .aligncenter {
  margin-bottom: 20px;
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.modContents .c_red {
  color: red;
}
.modContents h1,
.modContents h2,
.modContents h3,
.modContents h4,
.modContents h5,
.modContents h6 {
  margin: 0;
  padding: 0;
  border: none;
}
.modContents h1::before, .modContents h1::before, .modContents h1::before, .modContents h1::before, .modContents h1::before, .modContents h1::before, .modContents h1::after, .modContents h1::after, .modContents h1::after, .modContents h1::after, .modContents h1::after, .modContents h1::after,
.modContents h2::before,
.modContents h2::before,
.modContents h2::before,
.modContents h2::before,
.modContents h2::before,
.modContents h2::before,
.modContents h2::after,
.modContents h2::after,
.modContents h2::after,
.modContents h2::after,
.modContents h2::after,
.modContents h2::after,
.modContents h3::before,
.modContents h3::before,
.modContents h3::before,
.modContents h3::before,
.modContents h3::before,
.modContents h3::before,
.modContents h3::after,
.modContents h3::after,
.modContents h3::after,
.modContents h3::after,
.modContents h3::after,
.modContents h3::after,
.modContents h4::before,
.modContents h4::before,
.modContents h4::before,
.modContents h4::before,
.modContents h4::before,
.modContents h4::before,
.modContents h4::after,
.modContents h4::after,
.modContents h4::after,
.modContents h4::after,
.modContents h4::after,
.modContents h4::after,
.modContents h5::before,
.modContents h5::before,
.modContents h5::before,
.modContents h5::before,
.modContents h5::before,
.modContents h5::before,
.modContents h5::after,
.modContents h5::after,
.modContents h5::after,
.modContents h5::after,
.modContents h5::after,
.modContents h5::after,
.modContents h6::before,
.modContents h6::before,
.modContents h6::before,
.modContents h6::before,
.modContents h6::before,
.modContents h6::before,
.modContents h6::after,
.modContents h6::after,
.modContents h6::after,
.modContents h6::after,
.modContents h6::after,
.modContents h6::after {
  content: "";
  display: table;
  table-layout: fixed;
  clear: both;
}
.modContents ul {
  padding-left: 10px;
  margin-bottom: 30px;
  list-style: none;
}
.modContents ul li {
  padding-left: 20px;
  position: relative;
  word-wrap: break-word;
  margin-bottom: 10px;
}
.modContents ul li::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
  top: 6px;
  left: 2px;
  background: #EA6D9F;
}
.modContents ul.list_none {
  padding: 0;
}
.modContents ul.list_none li {
  padding: 0;
}
.modContents ul.list_none li::before {
  display: none;
}
.modContents ol {
  list-style: none;
  counter-reset: ol_li;
  padding-left: 10px;
  margin-bottom: 30px;
}
.modContents ol li {
  padding-left: 20px;
  position: relative;
  word-wrap: break-word;
  margin-bottom: 10px;
}
.modContents ol li::before {
  position: absolute;
  top: 0;
  left: 0;
  counter-increment: ol_li;
  content: counters(ol_li, ".");
  font-weight: bold;
  color: #3c3c3c;
}
.modContents .wp-caption,
.modContents img {
  max-width: 100%;
  height: auto;
}
.modContents .wp-caption p {
  margin: 0;
}
.modContents h2 {
  font-size: 30px;
  color: #3c3c3c;
  padding: 15px;
  margin-bottom: 50px;
  background-image: url("../img/common/bg_ttl1.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  text-align: center;
}
.modContents h3 {
  color: #3c3c3c;
  font-size: 22px;
  padding: 8px 15px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 40px;
  border-left: 10px solid #EA6D9F;
  background-color: #FDF0F5;
}
.modContents h4 {
  color: #3c3c3c;
  font-size: 20px;
  padding: 0;
  margin-bottom: 30px;
  font-weight: bold;
  text-align: left;
  border-bottom: 4px solid #EA6D9F;
}
.modContents h5 {
  color: #3c3c3c;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 0;
  border-bottom: 2px dotted #C5C5C6;
}
.modContents h6 {
  font-size: 20px;
  color: #323232;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 0px 0px 3px 0px;
  border-bottom: 2px dashed #323232;
}
.modContents p {
  color: #323232;
  margin-bottom: 30px;
}
.modContents input[type=radio],
.modContents input[type=checkbox] {
  border: 0;
  clip: unset;
  -webkit-clip-path: unset;
  clip-path: unset;
  height: auto;
  margin: auto;
  overflow: hidden;
  padding: 0;
  position: unset;
  white-space: nowrap;
  width: auto;
}
.modContents iframe {
  width: 100%;
}
.modContents #gallery {
  margin-bottom: 30px;
}
.modContents .gallery .gallery-item {
  padding: 2px;
}
.modContents .gallery .gallery-item img {
  border: none !important;
}
.modContents .btn_pdf {
  background: url("../img/common/btn_pdf.png") no-repeat right 5px center;
  background-size: 35px;
}
.modContents .btn_doc {
  background: url("../img/common/btn_doc.png") no-repeat right 5px center;
  background-size: 35px;
}
.modContents .btn_xls {
  background: url("../img/common/btn_xls.png") no-repeat right 5px center;
  background-size: 35px;
}
.modContents .btn_blank {
  padding: 0 40px 0 0 !important;
  background: url("../img/common/btn_blank.png") no-repeat right 5px center;
  background-size: 18px;
}
.modContents .btn_pdf,
.modContents .btn_doc,
.modContents .btn_xls,
.modContents .btn_blank {
  padding: 3px 45px 3px 0;
  display: inline;
}
.modContents .btn_link {
  text-align: center;
  background-color: #EA6D9F;
  border-radius: 40px;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-decoration: none;
  padding: 10px 25px 10px 15px;
  min-height: 68px;
  line-height: normal;
  width: 100%;
  max-width: 300px;
  position: relative;
  margin: 0 10px 10px 0;
}
.modContents .btn_link::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%) rotate(45deg);
}
.modContents .wpcf7-field-group br:nth-of-type(2) {
  display: none;
}
.modContents .wpcf7-field-group input[type=number] {
  width: 40px;
}
.modContents .wpcf7-field-group input[type=text] {
  width: 120px;
}
.modContents .wpcf7-field-group-add,
.modContents .wpcf7-field-group-remove {
  padding: 8px 18px;
  max-width: 50px;
  margin-right: 0;
}
.modContents .wpcf7-field-group-add:before,
.modContents .wpcf7-field-group-remove:before {
  content: none;
}
.modContents .scroll_wrap {
  width: 100%;
}
.modContents table {
  max-width: 100%;
  width: 100%;
  margin-bottom: 30px;
  border-collapse: collapse;
}
.modContents table th,
.modContents table td {
  padding: 20px;
  border: 1px solid #9b9e9e;
}
.modContents table thead th,
.modContents table thead td {
  text-align: center;
  font-weight: bold;
  background: #EA6D9F;
  color: #ffffff;
}
.modContents table tbody th {
  text-align: left;
  background: #FDF0F5;
  color: #3c3c3c;
}
.modContents table tbody td {
  text-align: left;
  background: #ffffff;
  color: #3c3c3c;
}
.modContents table .wp-caption {
  width: auto !important;
}
.modContents table .wp-caption p {
  margin: 0;
}
.modContents table .wp-caption img {
  max-width: 100%;
  padding: 0;
  width: auto;
  height: auto;
}
.modContents table.tbl_none {
  margin: 0;
  padding: 0;
}
.modContents table.tbl_none th,
.modContents table.tbl_none td {
  background: none;
  border: none;
  vertical-align: top;
  text-align: left;
  margin: 0;
  padding: 10px;
}
.modContents table.tbl_fix {
  table-layout: fixed;
}
.modContents table.tbl_full {
  width: 100% !important;
}
.modContents table.cal_table th,
.modContents table.cal_table td {
  padding: 5px;
}
.modContents table.cal_table tr.cal-table-odd th,
.modContents table.cal_table tr.cal-table-odd td {
  background: #f2f2f2;
}

@media screen and (max-width: 767px) {
  .modContents .alignleft,
.modContents .alignright {
    display: block;
    float: none;
    margin: 0 auto 20px auto;
  }
  .modContents .scroll_wrap {
    margin-bottom: 30px;
    overflow: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
  .modContents .scroll_wrap:before {
    content: "→スクロールできます。";
  }
  .modContents .tbl_scroll {
    margin-bottom: 0;
    max-width: 767px !important;
    width: 767px !important;
  }
  .modContents .tbl_responsive,
.modContents .tbl_responsive thead,
.modContents .tbl_responsive tfoot,
.modContents .tbl_responsive tbody,
.modContents .tbl_responsive tr,
.modContents .tbl_responsive th,
.modContents .tbl_responsive td {
    display: block;
    width: 100% !important;
  }
  .modContents .list_1 li {
    width: 100%;
  }
  .modContents .list_1 li:nth-child(even) {
    margin-left: 0;
  }
}
ul.modListJump {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
ul.modListJump li {
  padding: 0;
  margin: 0;
}
ul.modListJump li:before {
  display: none;
}
ul.modListJump li a {
  display: inline-block;
  color: #0461b0;
  position: relative;
  padding: 10px 15px 10px 35px;
}
ul.modListJump li a:before {
  content: "";
  position: absolute;
  top: 13px;
  left: 15px;
  width: 10px;
  height: 10px;
  border-top: 2px solid #3c3c3c;
  border-right: 2px solid #3c3c3c;
  transform: rotate(135deg);
}

ul.modListTable {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: wrap;
}
ul.modListTable li {
  background: white;
  color: #3c3c3c;
  width: calc( 100% / 4 - 10px );
  padding: 0;
  margin-bottom: 20px;
  border: 5px solid #FBE2EC;
}
@media only screen and (max-width: 992px) {
  ul.modListTable li {
    width: calc( 100% / 2 - 10px );
  }
}
@media only screen and (max-width: 768px) {
  ul.modListTable li {
    width: 100%;
  }
}
ul.modListTable li:before {
  display: none;
}
ul.modListTable li a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #3c3c3c;
  text-decoration: none;
  height: 100%;
  width: 100%;
  font-weight: bold;
  padding: 15px 30px 15px 20px;
}
ul.modListTable li a:before {
  content: "";
  position: absolute;
  top: calc( 100% / 2 - 4px );
  right: 15px;
  width: 10px;
  height: 10px;
  border-top: 1px solid #3c3c3c;
  border-right: 1px solid #3c3c3c;
  transform: rotate(45deg);
}

ul.modListSns .snsicon1,
ul.modListSns .snsicon2,
ul.modListSns .snsicon3 {
  position: fixed;
  right: 5px;
}
ul.modListSns .snsicon1 img,
ul.modListSns .snsicon2 img,
ul.modListSns .snsicon3 img {
  width: 35px;
}
ul.modListSns .snsicon1 {
  bottom: 210px;
}
ul.modListSns .snsicon2 {
  bottom: 170px;
}
ul.modListSns .snsicon3 {
  bottom: 130px;
}

/* 一日の流れ 20210416追加 */
.p-top-section3__body {
  margin: 50px auto 100px;
}

.p-top-section3-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -30px 50px;
  padding-left: 0;
}
@media only screen and (max-width: 992px) {
  .p-top-section3-list {
    justify-content: flex-start;
    margin: 0 -10px;
  }
}
@media only screen and (max-width: 768px) {
  .p-top-section3-list {
    margin: 0 -8px;
  }
}

.p-top-section3-list__item {
  padding: 0 30px;
  width: 50%;
}
.p-top-section3-list__item::before {
  display: none;
}
@media only screen and (max-width: 992px) {
  .p-top-section3-list__item {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 768px) {
  .p-top-section3-list__item {
    padding: 0 8px;
  }
}
@media only screen and (max-width: 768px) {
  .p-top-section3-list__item {
    width: 100%;
    padding-left: 0 !important;
  }
}
.p-top-section3-list__item__ttl {
  font-family: M PLUS\ 1p, sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
}
.p-top-section3-list__item:first-child .p-top-section3-list__item__ttl {
  border-bottom: 2px #ea6d9f solid;
  color: #ea6d9f;
}
.p-top-section3-list__item:first-child .p-schedule-table .p-schedule-table__time {
  background-color: #ea6d9f;
}
.p-top-section3-list__item:first-child .p-schedule-table td {
  border-left: 5px #fbe2ec solid !important;
}
.p-top-section3-list__item:first-child .p-schedule-table tr:last-child td {
  border-left: none !important;
}
.p-top-section3-list__item:last-child .p-top-section3-list__item__ttl {
  border-bottom: 2px #00b7de solid;
  color: #00b7de;
}
.p-top-section3-list__item:last-child .p-schedule-table .p-schedule-table__time {
  background-color: #00b7de;
}
.p-top-section3-list__item:last-child .p-schedule-table td {
  border-left: 5px #ccf1f8 solid !important;
}
.p-top-section3-list__item:last-child .p-schedule-table tr:last-child td {
  border-left: none !important;
}

.p-schedule-table {
  margin-top: 50px;
  padding-left: 55px;
}
.p-schedule-table table,
.p-schedule-table tbody,
.p-schedule-table thead,
.p-schedule-table tfoot,
.p-schedule-table th,
.p-schedule-table td {
  border: none !important;
}
@media only screen and (max-width: 992px) {
  .p-schedule-table table,
.p-schedule-table tbody,
.p-schedule-table thead,
.p-schedule-table tfoot,
.p-schedule-table th,
.p-schedule-table td {
    display: block !important;
    width: 100% !important;
  }
}
.p-schedule-table td {
  padding: 5px 0 60px 100px !important;
  position: relative;
  vertical-align: top;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .p-schedule-table td {
    padding: 5px 0 60px 70px !important;
  }
}
.p-schedule-table td .p-schedule-table__time {
  position: absolute;
  top: 0;
  left: -55px;
  text-align: center;
  border-radius: 50px;
  color: white;
  display: inline-block;
  text-decoration: none;
  padding: 5px 10px;
  line-height: normal;
  width: 100%;
  max-width: 100px;
  z-index: 2;
}
@media only screen and (max-width: 992px) {
  .p-schedule-table td.nonebox {
    padding: 0 !important;
  }
}
@media only screen and (max-width: 992px) {
  .p-schedule-table td.spnone {
    display: none !important;
  }
}
@media only screen and (max-width: 992px) {
  .p-schedule-table td.spnoneheight {
    padding: 0 !important;
  }
}
@media only screen and (max-width: 992px) {
  .p-schedule-table tr:first-child td:first-child {
    display: none !important;
  }
}
.p-schedule-table tr:last-child td {
  padding-bottom: 0 !important;
  border-left: none !important;
}
.p-schedule-table tr:last-child td:last-child {
  position: relative;
  /* &:before {
    content: " ";
    width: 20px;
    height: 20px;
    border-radius: 20px;
    position: absolute;
    bottom: -10px;
    left: -10px;
    background: linear-gradient(90deg, #1055a3, #27c5ff);
  } */
}
.p-schedule-table .p-schedule-table__ttl {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  color: #3b3b3b;
  margin-bottom: 10px;
}
.p-schedule-table .pcnone {
  display: none;
}

/* ==========================
 * Foundation
 * ========================== */
/* ==========================
 * Layout
 * ========================== */
/* ==========================
 * Components
 * ========================== */