@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
c-button
*/
.c-button1 {
  align-items: center;
  background-color: variables.$COLOR_PINK;
  border-radius: 100px;
  color: variables.$COLOR_WHITE;
  display: inline-flex;
  @include mixin.fz(18);
  height: 53px;
  justify-content: center;
  line-height: 1.2;
  position: relative;
  text-decoration: none;
  width: 240px;

  @include mixin.mq-tbP {
    @include mixin.fz(13);
    height: 40px;
    width: 185px;
  }

  &::after {
    background: {
      image: url("#{mixin.set_common_path('icon_button_arrow1.svg')}");
      repeat: no-repeat;
      position: center;
      size: contain;
    }
    content: '';
    height: 12px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 7px;

    @include mixin.mq-tbP {
      height: 9px;
      width: 6px;
    }
  }
}

.c-button-link1 {
  @include mixin.fz(15);
  padding-left: 15px;
  position: relative;
  text-decoration: none;

  @include mixin.mq-tbP {
    padding-left: 13px;
    @include mixin.fz(13);
  }

  &::before {
    background: {
      image: url("#{mixin.set_common_path('icon_button_arrow2.svg')}");
      repeat: no-repeat;
      size: contain;
    }
    content: '';
    height: 12px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;

    @include mixin.mq-tbP {
      height: 10px;
      width: 7px;
    }
  }
}
