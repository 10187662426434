@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
modContents
*/

#pankuzu {
  position: absolute;
  top: 10px;
  left: 0;
  @include mixin.mq-tbP {
    display: none;
  }
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    li {
      color: map_get( variables.$BASE, color );
      span,
      a {
        color: map_get( variables.$BASE, color-page-link );
        text-decoration: none;
        position: relative;
        margin-right: 25px;
        &:after {
          content: '';
          position: absolute;
          top: 6px;
          right: -15px;
          width: 9px;
          height: 9px;
          border-top: 1px solid map_get( variables.$BASE, color );
          border-right: 1px solid map_get( variables.$BASE, color );
          transform: rotate(45deg);
        }
      }
    }
  }
}
.wp-pagenavi {
  display: table;
  margin: 50px auto 20px;
  border-collapse: separate;
  border-spacing: 15px 0;
  a,
  span {
    font-weight: bold;
    height: 40px;
    width: 40px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 0 !important;
  }
  a {
    border: 2px solid #dfdfdb !important;
    &:hover {
      border: 2px solid #0060ae !important;
      color: #fff;
      background: #0060ae;
      text-decoration: none;
    }
    &.previouspostslink,
    &.nextpostslink {
      color: #fff;
      border: 2px solid #9b9ea4 !important;
      background: #9b9ea4;
    }
  }
  span {
    border: 2px solid #0060ae !important;
    color: #fff;
    background: #0060ae;
    &.extend {
      color: #9b9ea4;
      background: none;
      border: none !important;
    }
  }
}

.modContentsContainer {
  padding: 5px 0 50px;
}

.modContents {
  a {
    position: relative;
    color: map_get( variables.$BASE, color-page-link );
  }

  &::after {
    content: '';
    display: table;
    table-layout: fixed;
    clear: both;
  }

  .alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
    margin-bottom: 1.5em;
  }

  .alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
    margin-bottom: 1.5em;
  }

  .aligncenter {
    margin-bottom: 20px;
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .c_red {
    color: red;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    border: none;
    &::before,
    &::before,
    &::before,
    &::before,
    &::before,
    &::before,
    &::after,
    &::after,
    &::after,
    &::after,
    &::after,
    &::after {
      content: '';
      display: table;
      table-layout: fixed;
      clear: both;
    }
  }

  ul {
    padding-left: 10px;
    margin-bottom: 30px;
    list-style: none;
    li {
      padding-left: 20px;
      position: relative;
      word-wrap: break-word;
      margin-bottom: 10px;
      &::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 5px;
        position: absolute;
        top: 6px;
        left: 2px;
        background: #EA6D9F;
      }
    }
    &.list_none {
      padding: 0;
      li {
        padding: 0;
        &::before {
          display: none;
        }
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: ol_li;
    padding-left: 10px;
    margin-bottom: 30px;

    li {
      padding-left: 20px;
      position: relative;
      word-wrap: break-word;
      margin-bottom: 10px;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        counter-increment: ol_li;
        content: counters(ol_li, '.');
        font-weight: bold;
        color: #3c3c3c;
      }
    }
  }

  .wp-caption,
  img {
    max-width: 100%;
    height: auto;
  }
  .wp-caption {
    p {
      margin: 0;
    }
  }

  h2 {
    font-size: 30px;
    color: map_get( variables.$BASE, color );
    padding: 15px;
    margin-bottom: 50px;
    background: {
        image: url('#{mixin.set_image_path('common/bg_ttl1.png')}');
        repeat: no-repeat;
        position: center bottom;
    }
    text-align: center;
  }

  h3 {
    color: map_get( variables.$BASE, color );
    font-size: 22px;
    padding: 8px 15px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 40px;
    border-left: 10px solid #EA6D9F;
    background-color: #FDF0F5;
  }

  h4 {
    color: map_get( variables.$BASE, color );
    font-size: 20px;
    padding: 0;
    margin-bottom: 30px;
    font-weight: bold;
    text-align: left;
    border-bottom: 4px solid #EA6D9F;
  }

  h5 {
    color: map_get( variables.$BASE, color );
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 0;
    border-bottom: 2px dotted #C5C5C6;
  }

  h6 {
    font-size: 20px;
    color: #323232;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 0px 0px 3px 0px;
    border-bottom: 2px dashed #323232;
  }

  p {
    color: #323232;
    margin-bottom: 30px;
  }

  input[type='text'],
  input[type='email'],
  input[type='url'],
  input[type='password'],
  input[type='search'],
  input[type='number'],
  input[type='tel'],
  input[type='range'],
  input[type='date'],
  input[type='month'],
  input[type='week'],
  input[type='time'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='color'],
  textarea {
  }

  input[type='text']:focus,
  input[type='email']:focus,
  input[type='url']:focus,
  input[type='password']:focus,
  input[type='search']:focus,
  input[type='number']:focus,
  input[type='tel']:focus,
  input[type='range']:focus,
  input[type='date']:focus,
  input[type='month']:focus,
  input[type='week']:focus,
  input[type='time']:focus,
  input[type='datetime']:focus,
  input[type='datetime-local']:focus,
  input[type='color']:focus,
  textarea:focus {
  }

  input[type='radio'],
  input[type='checkbox'] {
    border: 0;
    clip: unset;
    -webkit-clip-path: unset;
    clip-path: unset;
    height: auto;
    margin: auto;
    overflow: hidden;
    padding: 0;
    position: unset;
    white-space: nowrap;
    width: auto;
  }

  iframe {
    width: 100%;
  }

  #gallery {
    margin-bottom: 30px;
  }
  .gallery {
    .gallery-item {
      padding: 2px;
      img {
        border: none !important;
      }
    }
  }

  .btn_pdf {
    background: url('#{mixin.set_image_path('common/btn_pdf.png')}') no-repeat right 5px center;
    background-size: 35px;
  }
  .btn_doc {
    background: url('#{mixin.set_image_path('common/btn_doc.png')}') no-repeat right 5px center;
    background-size: 35px;
  }
  .btn_xls {
    background: url('#{mixin.set_image_path('common/btn_xls.png')}') no-repeat right 5px center;
    background-size: 35px;
  }
  .btn_blank {
    padding: 0 40px 0 0 !important;
    background: url('#{mixin.set_image_path('common/btn_blank.png')}') no-repeat right 5px center;
    background-size: 18px;
  }
  .btn_pdf,
  .btn_doc,
  .btn_xls,
  .btn_blank {
    padding: 3px 45px 3px 0;
    display: inline;
  }

  .btn_link {
    text-align: center;
    background-color: #EA6D9F;
    border-radius: 40px;
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-decoration: none;
    padding: 10px 25px 10px 15px;
    min-height: 68px;
    line-height: normal;
    width: 100%;
    max-width: 300px;
    position: relative;
    margin: 0 10px 10px 0;
    &::after {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-top: 2px solid white;
      border-right: 2px solid white;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%) rotate(45deg);
    }
  }

  .wpcf7-field-group {
    br {
      &:nth-of-type(2) {
        display: none;
      }
    }
    input[type='number'] {
      width: 40px;
    }
    input[type='text'] {
      width: 120px;
    }
  }
  .wpcf7-field-group-add,
  .wpcf7-field-group-remove {
    padding: 8px 18px;
    max-width: 50px;
    margin-right: 0;
    &:before {
      content: none;
    }
  }

  .scroll_wrap {
    width: 100%;
  }
  table {
    max-width: 100%;
    width: 100%;
    margin-bottom: 30px;
    border-collapse: collapse;
    th,
    td {
      padding: 20px;
      border: 1px solid #9b9e9e;
    }
    thead {
      th,
      td {
        text-align: center;
        font-weight: bold;
        background: #EA6D9F;
        color: #ffffff;
      }
    }
    tbody {
      th {
        text-align: left;
        background: #FDF0F5;
        color: map_get( variables.$BASE, color );
      }
      td {
        text-align: left;
        background: #ffffff;
        color: map_get( variables.$BASE, color );
      }
    }
    .wp-caption {
      p {
        margin: 0;
      }
      width: auto !important;
      img {
        max-width: 100%;
        padding: 0;
        width: auto;
        height: auto;
      }
    }
    &.tbl_none {
      margin: 0;
      padding: 0;
      th,
      td {
        background: none;
        border: none;
        vertical-align: top;
        text-align: left;
        margin: 0;
        padding: 10px;
      }
    }
    &.tbl_fix {
      table-layout: fixed;
    }
    &.tbl_full {
      width: 100% !important;
    }
    &.cal_table {
      th,
      td {
        padding: 5px;
      }
      tr {
        &.cal-table-odd {
          th,
          td {
            background: #f2f2f2;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .modContents {
    .alignleft,
    .alignright {
      display: block;
      float: none;
      margin: 0 auto 20px auto;
    }
    .scroll_wrap {
      margin-bottom: 30px;
      overflow: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -webkit-overflow-scrolling: touch;
      &:before {
        content: '→スクロールできます。';
      }
    }
    .tbl_scroll {
      margin-bottom: 0;
      max-width: 767px !important;
      width: 767px !important;
    }
    .tbl_responsive,
    .tbl_responsive thead,
    .tbl_responsive tfoot,
    .tbl_responsive tbody,
    .tbl_responsive tr,
    .tbl_responsive th,
    .tbl_responsive td {
      display: block;
      width: 100% !important;
    }
    .list_1 li {
      width: 100%;
    }
    .list_1 li:nth-child(even) {
      margin-left: 0;
    }
  }
}

ul.modListJump {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  li {
    padding: 0;
    margin: 0;
    &:before {
      display: none;
    }
    a {
      display: inline-block;
      color: #0461b0;
      position: relative;
      padding: 10px 15px 10px 35px;
      &:before {
        content: '';
        position: absolute;
        top: 13px;
        left: 15px;
        width: 10px;
        height: 10px;
        border-top: 2px solid map_get( variables.$BASE, color );
        border-right: 2px solid map_get( variables.$BASE, color );
        transform: rotate(135deg);
      }
    }
  }
}

ul.modListTable {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: wrap;
  li {
    background: white;
    color: map_get( variables.$BASE, color );
    width: calc( 100% / 4 - 10px );
    padding: 0;
    margin-bottom: 20px;
    border: 5px solid #FBE2EC;
    @include mixin.mq-tbL {
      width: calc( 100% / 2 - 10px );
    }
    @include mixin.mq-tbP {
      width: 100%;
    }
    &:before {
      display: none;
    }
    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      color: map_get( variables.$BASE, color );
      text-decoration: none;
      height: 100%;
      width: 100%;
      font-weight: bold;
      padding: 15px 30px 15px 20px;
      &:before {
        content: '';
        position: absolute;
        top: calc( 100% / 2 - 4px );
        right: 15px;
        width: 10px;
        height: 10px;
        border-top: 1px solid map_get( variables.$BASE, color );
        border-right: 1px solid map_get( variables.$BASE, color );
        transform: rotate(45deg);
      }
    }
  }
}

ul.modListSns {
  .snsicon1,
  .snsicon2,
  .snsicon3 {
    position: fixed;
    right: 5px;
    img {
      width: 35px;
    }
  }
  .snsicon1 {
    bottom: 210px;
  }
  .snsicon2 {
    bottom: 170px;
  }
  .snsicon3 {
    bottom: 130px;
  }
}

/* 一日の流れ 20210416追加 */
.p-top-section3__body {
  margin: 50px auto 100px;
}

.p-top-section3-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -30px 50px;
  padding-left: 0;

  @include mixin.mq-tbL {
    justify-content: flex-start;
    margin: 0 -10px;
  }

  @include mixin.mq-tbP {
    margin: 0 -8px;
  }
}

.p-top-section3-list__item {
  padding: 0 30px;
  width: 50%;

  &::before {
    display: none;
  }

  @include mixin.mq-tbL {
    padding: 0 10px;
  }

  @include mixin.mq-tbP {
    padding: 0 8px;
  }

  @include mixin.mq-tbP {
    width: 100%;
    padding-left: 0 !important;
  }

  &__ttl {
    font-family: M PLUS\ 1p,
    sans-serif;
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
  }

  &:first-child {

    .p-top-section3-list__item__ttl {
      border-bottom: 2px #ea6d9f solid;
      color: #ea6d9f;
    }

    .p-schedule-table {
      .p-schedule-table__time {
        background-color: #ea6d9f;
      }

      td {
        border-left: 5px #fbe2ec solid !important;
      }

      tr {
        &:last-child {

          td {
            border-left: none !important;
          }
        }
      }
    }
  }

  &:last-child {
    .p-top-section3-list__item__ttl {
      border-bottom: 2px #00b7de solid;
      color: #00b7de;
    }

    .p-schedule-table {
      .p-schedule-table__time {
        background-color: #00b7de;
      }

      td {
        border-left: 5px #ccf1f8 solid !important;
      }

      tr {
        &:last-child {

          td {
            border-left: none !important;
          }
        }
      }
    }
  }
}

.p-schedule-table {
  margin-top: 50px;
  padding-left: 55px;

  table,
  tbody,
  thead,
  tfoot,
  th,
  td {
    border: none !important;

    @include mixin.mq-tbL {
      display: block !important;
      width: 100% !important;
    }
  }

  td {
    padding: 5px 0 60px 100px !important;
    position: relative;
    vertical-align: top;
    width: 100%;

    @include mixin.mq-tbP {
      padding: 5px 0 60px 70px !important;
    }

    .p-schedule-table__time {
      position: absolute;
      top: 0;
      left: -55px;
      text-align: center;
      border-radius: 50px;
      color: white;
      display: inline-block;
      text-decoration: none;
      padding: 5px 10px;
      line-height: normal;
      width: 100%;
      max-width: 100px;
      z-index: 2;
    }

    &.nonebox {
      @include mixin.mq-tbL {
        padding: 0 !important;
      }
    }

    &.spnone {
      @include mixin.mq-tbL {
        display: none !important;
      }
    }

    &.spnoneheight {
      @include mixin.mq-tbL {
        padding: 0 !important;
      }
    }
  }

  tr {
    &:first-child {
      td {
        &:first-child {
          @include mixin.mq-tbL {
            display: none !important;
          }
        }
      }
    }

    &:last-child {
      td {
        padding-bottom: 0 !important;
        border-left: none !important;

        &:last-child {
          position: relative;

          /* &:before {
            content: " ";
            width: 20px;
            height: 20px;
            border-radius: 20px;
            position: absolute;
            bottom: -10px;
            left: -10px;
            background: linear-gradient(90deg, #1055a3, #27c5ff);
          } */
        }
      }
    }
  }

  .p-schedule-table__ttl {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    color: #3b3b3b;
    margin-bottom: 10px;
  }

  .pcnone {
    display: none;
  }
}
