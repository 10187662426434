@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
c-block
*/

.c-block-entry {
  background: {
    image: url("#{mixin.set_common_path('bg_entry_pc.jpg')}");
    repeat: no-repeat;
    position: left center;
    size: cover;
  }
  padding: 75px 0;
  position: relative;
  text-align: center;

  @include mixin.mq-tbL {
    background-image: url("#{mixin.set_common_path('bg_entry_sp.jpg')}");
    padding: 30px 0;
  }

  &::before,
  &::after {
    background: {
      image: url("#{mixin.set_common_path('bg_header_line.png')}");
      repeat: repeat-x;
      size: auto 6px;
      position: top center;
    }
    content: '';
    height: 6px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}

.c-block-entry__inner {
  padding: 0 320px;
  position: relative;

  @include mixin.mq-tbL {
    padding: 0;
  }
}
.c-block-entry-catch {
  font-family: variables.$FONT_M_PLUS;
  @include mixin.fz(20);
  letter-spacing: 0.1em;
  line-height: 1;
  margin-bottom: 10px;

  @include mixin.mq-tbL {
    @include mixin.fz(12);
    margin-bottom: 5px;
  }
}
.c-block-entry-catch__inner {
  display: inline-block;
  padding: 0 15px;
  position: relative;

  &::before,
  &::after {
    background: currentColor;
    border-radius: 100px;
    content: '';
    height: 25px;
    position: absolute;
    top: 50%;
    width: 2px;
  }
  &::before {
    left: 0;
    transform: rotate(-30deg) translateY(-50%);
  }
  &::after {
    right: 0;
    transform: rotate(30deg) translateY(-50%);
  }
}

.c-block-entry-title {
  font-family: variables.$FONT_M_PLUS;
  @include mixin.fz(32);
  letter-spacing: 0.1em;
  line-height: 1;

  @include mixin.mq-tbL {
    @include mixin.fz(30);
  }
  @include mixin.mq-sp {
    @include mixin.fz(28);
  }
}
.c-block-entry-buttons {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 320px;

  @include mixin.mq-tbL {
    display: none;
  }
}
.c-block-entry-buttons__item:nth-child(n + 2) {
  margin-top: 25px;
}

.c-block-entry__spLink {
  display: none;

  @include mixin.mq-tbL {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
