@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

$COMMON_PATH: '../img/common/';

#footer {
}

.l-footer {
}

.l-footer-pageTop {
  border-radius: 100px;
  box-shadow: 1px 1px 3px 3px rgba(#000, 0.1);
  bottom: 16px;
  overflow: hidden;
  position: fixed;
  right: 16px;
  transition: opacity 0.3s linear, visibility 0.3s linear;
  z-index: 2;

  @include mixin.mq-tbL {
    width: 50px;
  }
}
.l-footer-pageTop[aria-hidden='true'] {
  opacity: 0;
  visibility: hidden;
}

.l-footer-main {
  background: {
    image: url("#{mixin.set_common_path('bg_footer.png')}");
    size: cover;
    repeat: no-repeat;
    position: center;
  }
  padding: 60px 0 115px;

  @include mixin.mq-tbL {
    padding: 25px 0;
  }
}
.l-footer-main__inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}
.l-footer-main__item {
  width: 50%;

  @include mixin.mq-tbL {
    width: 100%;
  }
}

.l-footer-main-fb {
  text-align: right;

  @include mixin.mq-tbL {
    text-align: center;
  }
}

.l-footer-main-list {
  display: flex;
  @include mixin.fz(16);

  @include mixin.mq-tbL {
    display: none;
  }
}

.l-footer-main-list__block {
  width: 270px;
}

.l-footer-main-list__item {
  position: relative;
  &::before {
    content: '＞';
    display: inline-block;
    width: 1em;
  }
}
.l-footer-main-list__item:nth-child(n + 2) {
  margin-top: 15px;
}

.l-footer-main-list__target {
  line-height: 1.2;
  text-decoration: none;
}

.l-footer-main-list-child {
  padding: 15px 0 0 20px;
}

.l-footer-main-copyright {
  line-height: 1.2;
  margin-top: 50px;

  @include mixin.mq-tbL {
    margin-top: 25px;
    text-align: center;
  }
}
