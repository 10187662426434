@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
c-form
*/

/* form
------------------------------------------------------------*/
textarea,
input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
select {
  border: solid 1px hsl(0, 0%, 67%);
  border-radius: 5px;
  padding: 10px;

  &.error {
    background-color: hsl(0, 100%, 97%);
    border: solid 2px map_get(variables.$COLOR, strong);
  }
}

/* radio
-------------*/
input[type='radio'] {
  @include mixin.accessibilityHidden;

  & + .label {
    cursor: pointer;
    display: inline-block;
    padding: 3px 10px 3px 24px;
    position: relative;
    width: 100%;

    &::before,
    &::after {
      border-radius: 100%;
      content: '';
      position: absolute;
      top: 50%;
      transition: all 0.2s;
    }

    &::before {
      background: hsl(0, 0%, 93%);
      border: 1px solid hsl(0, 0%, 80%);
      height: 16px;
      left: 0;
      margin-top: -9px;
      width: 16px;
    }

    &::after {
      background: map_get(variables.$COLOR, blue);
      height: 10px;
      left: 3px;
      margin-top: -6px;
      opacity: 0;
      transform: scale(0.5);
      width: 10px;
    }

    &:hover {
      &::before {
        background: hsl(0, 0%, 100%);
      }
    }
  }

  &.error {
    & + .label {
      &::before {
        background-color: rgb(255 242 242);
        border: 1px solid map_get(variables.$COLOR, strong);
      }
    }
  }

  &:checked {
    & + .label {
      &::before {
        background: map-get(variables.$COLOR, white);
        border: 1px solid map_get(variables.$COLOR, blue);
      }

      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

/* checkbox
-------------*/
input[type='checkbox'] {
  @include mixin.accessibilityHidden;

  & + .label {
    cursor: pointer;
    display: inline-block;
    padding: 3px 10px 3px 22px;
    position: relative;
    transition: all 0.2s;

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    &::before {
      background: hsl(0, 0%, 96%);
      border: 1px solid hsl(0, 0%, 80%);
      border-radius: 3px;
      height: 14px;
      left: 0;
      margin-top: -8px;
      top: 50%;
      width: 14px;
    }

    &::after {
      border: {
        bottom: 2px solid map_get(variables.$COLOR, blue);
        left: 2px solid map_get(variables.$COLOR, blue);
      }
      height: 4px;
      left: 3px;
      margin-top: -4px;
      opacity: 0;
      top: 50%;
      transform: rotate(-45deg) scale(0.5);
      width: 8px;
    }

    &:hover {
      &::before {
        background: map-get(variables.$COLOR, white);
      }
    }
  }

  &:checked {
    & + .label {
      &::before {
        background: map-get(variables.$COLOR, white);
        border: 1px solid map_get(variables.$COLOR, blue);
      }

      &::after {
        opacity: 1;
        transform: rotate(-45deg) scale(1);
      }
    }
  }
}
