@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
c-list
*/

.c-list-normal {
  margin: 0 0 0 2em;
}

ul.c-list-normal {
  li {
    &:before {
      content: '\0030fb';
      display: inline-block;
      margin-left: -2em;
      text-align: center;
      width: 2em;
    }
  }
}

ol.c-list-normal li {
  list-style: decimal;
}

/* indent */
.c-list-indent01 li {
  margin: 0 0 0 1em;
  text-indent: -1em;
}

.c-list-indent02 li {
  margin: 0 0 0 2.5em;
  text-indent: -2.5em;
}

.c-dl-form {
  margin: 0 0 20px;

  dt {
    clear: both;
    float: left;
    padding: 33px 0 20px 63px;
    position: relative;
    width: 16em;
    @include mixin.mq-tbP {
      float: none;
      padding: 33px 0 0 63px;
      width: auto;
    }

    &.required:before,
    &.any:before {
      color: map-get(variables.$COLOR, white);
      left: 0;
      padding: 2px 10px;
      position: absolute;
      top: 33px;
      @include mixin.fz(11);
    }

    &.required:before {
      background: map_get(variables.$COLOR, strong);
      content: '必須';
    }

    &.any:before {
      background: hsl(0, 0%, 60%);
      content: '任意';
    }
  }

  dd {
    border-bottom: solid 1px hsl(0, 0%, 80%);
    padding: 10px 0 10px 17em;
    @include mixin.mq-tbP {
      padding: 10px 0;
    }

    .parts {
      padding: 10px 0;

      textarea,
      input[type='text'],
      input[type='email'] {
        width: 100%;
      }

      &.radio_inline,
      &.check_inline {
        > div {
          display: inline-block;
        }
      }

      &.name {
        display: flex;
        justify-content: space-between;

        > div {
          width: 48.5%;
        }
      }

      &.post,
      &.tel {
        display: flex;

        .hyphen {
          padding: 10px 10px 0 10px;
          @include mixin.mq-sp {
            padding: 10px 5px 0 5px;
          }
        }
      }

      &.password {
        input {
          max-width: 300px;
          width: 100%;
          @include mixin.mq-sp {
            max-width: none;
          }
        }

        .text {
          padding: 10px 0;
        }
      }
    }
  }

  .validationError {
    color: map_get(variables.$COLOR, strong);
  }
}

.c-list-breadcrumb {
  &-item {
    display: inline;
    @include mixin.mq-tbP {
    }

    &:not(:last-child):after {
      content: '\3E';
      margin: 0 8px;
    }

    &.-home {
      &:before {
        display: none;
      }
    }
  }

  &-link {
    &:hover {
    }
  }
}

.c-list-news1 {
  @include mixin.fz(15);

  @include mixin.mq-tbP {
    @include mixin.fz(13);
  }
}
.c-list-news1__item:nth-child(n + 2) {
  margin-top: 25px;
}
.c-list-news1__target {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
}
.c-list-news1__date {
  width: 160px;

  @include mixin.mq-tbP {
    width: 115px;
  }
}
.c-list-news1__category {
  background-color: #ccc;
  color: variables.$COLOR_WHITE;
  @include mixin.fz(14);
  font-weight: 700;
  line-height: 1;
  padding: 5px 0;
  text-align: center;
  width: 70px;

  @include mixin.mq-tbP {
    padding: 4px 0;
    @include mixin.fz(10);
    width: 55px;
  }
}

.c-list-news1__category--category1 {
  background-color: variables.$COLOR_CATEGORY1;
}
.c-list-news1__category--category2 {
  background-color: variables.$COLOR_CATEGORY2;
}

.c-list-news1__title {
  flex: 1;
  padding-left: 30px;
  @include mixin.mq-tbP {
    flex: auto;
    margin-top: 5px;
    padding-left: 0;
    width: 100%;
  }
}
.c-list-news1__new {
  color: variables.$COLOR_PINK;
  @include mixin.fz(12);
  font-weight: 700;
}
