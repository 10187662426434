@use "variables" as variables;
@use "mixin" as mixin;

@font-face {
  font-family: 'Yu Gothic';
  src: local('Yu Gothic Medium'), local('YuGothic-Medium');
}
@font-face {
  font-family: 'Yu Gothic';
  font-weight: bold;
  src: local('Yu Gothic Bold'), local('YuGothic-Bold');
}

/* link
------------------------------------------------------------*/
a {
  color: map_get(variables.$BASE, color-link);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

a,
button {
  &:hover {
    opacity: 0.8;
    @include mixin.mq-tbL {
      opacity: 1;
    }
  }
}

/* Fonts
------------------------------------------------------------*/
html {
  transition: font-size 0.3s;

  &[data-font='small'] {
    font-size: 12px;
    @include mixin.mq-tbL {
      font-size: 16px;
    }
  }

  &[data-font='large'] {
    font-size: 18px;
    @include mixin.mq-tbL {
      font-size: 16px;
    }
  }
}

body {
  color: map_get(variables.$BASE, color);
  line-height: map_get(variables.$BASE, line-height);
  overflow-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  font: {
    family: variables.$FONT_BASE;
    weight: 500;
  }
  @include mixin.fz;
  @include mixin.mq-tbP {
    @include mixin.fz(13);
  }

  &.noScroll {
    overflow: hidden;
    @include mixin.mq-tbP {
      height: 100vh;
      left: 0;
      position: fixed;
      width: 100%;
    }
  }
}

input,
button,
textarea,
select {
  color: map_get(variables.$BASE, color);
  font-family: variables.$FONT_BASE;
  line-height: map_get(variables.$BASE, line-height);
}
