@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

$COMMON_PATH: '../img/common/';

#root {
  overflow: hidden;
}

.l-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
