@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

$COMMON_PATH: '../img/common/';

#header {
}

.l-header {
  @include mixin.mq-tbL {
    padding-top: 66px;
  }
}

.l-header-main {
  background: {
    image: url("#{mixin.set_common_path('bg_header_line.png')}");
    repeat: repeat-x;
    size: auto 6px;
    position: top center;
  }
  font-family: variables.$FONT_M_PLUS;
  padding-bottom: 25px;
  padding-top: 6px;
  position: relative;
  z-index: 3;

  @include mixin.mq-tbL {
    background-color: variables.$COLOR_WHITE;
    left: 0;
    padding: 6px 0 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
}

.l-header-main__container {
  margin: 0 auto;
  max-width: 1530px;
  padding: 0 15px;
}

.l-header-main__inner {
  position: relative;
}

.l-header-logo {
  align-items: center;
  display: flex;
  height: 100px;

  @include mixin.mq-tbL {
    height: 60px;
    width: 157px;
  }
}

.l-nav {
  @include mixin.mq-tbL {
    background-color: variables.$COLOR_WHITE;
    height: calc(100% - 60px);
    left: 0;
    opacity: 0;
    overflow: auto;
    position: fixed;
    top: 60px;
    visibility: hidden;
    width: 100%;

    &[aria-hidden='false'] {
      opacity: 1;
      visibility: visible;
    }
  }
}

.l-nav-main {
  display: flex;
  flex-wrap: wrap;

  @include mixin.mq-tbL {
    display: block;
  }
}
.l-nav-main__item {
  flex: 1;
  position: relative;

  @include mixin.mq-tbL {
    border-top: 1px solid #c5c5c6;
    width: 100%;
  }

  &::after {
    background: {
      image: url("#{mixin.set_common_path('bg_nav_line.svg')}");
      repeat: no-repeat;
      position: center;
    }
    content: '';
    height: 65px;
    position: absolute;
    right: -1px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
  }
}
.l-nav-main__item:last-child {
  @include mixin.mq-tbL {
    border-bottom: 1px solid #c5c5c6;
  }
  &::after {
    display: none;
  }
}
.l-nav-main__item--spOnly {
  display: none;
  @include mixin.mq-tbP {
    display: block;
  }
}
.l-nav-main__target {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  text-align: center;
  text-decoration: none;
}

.l-nav-main__target--toggle {
  @include mixin.mq-tbL {
    position: relative;
    &::after {
      background: {
        image: url("#{mixin.set_common_path('icon_plus_nav.svg')}");
        size: contain;
        position: center;
        repeat: no-repeat;
      }
      content: '';
      height: 10px;
      position: absolute;
      right: 13px;
      top: calc(50% - 5px);
      width: 10px;
    }
    &[aria-expanded='true']::after {
      background-image: url("#{mixin.set_common_path('icon_minus_nav.svg')}");
    }
  }
}

.l-nav-main__icon {
  margin-bottom: 5px;
  width: 100%;

  @include mixin.mq-tbL {
    display: none;
  }
}
.l-nav-main__text {
  @include mixin.fz(18);
  line-height: 1.5;
  width: 100%;

  @include mixin.mq-pc {
    @include mixin.fz(16);
  }

  @include mixin.mq-tbL {
    @include mixin.fz(15);
    text-align: left;
    padding: 15px 20px;
    br {
      display: none;
    }
  }
}

.l-nav-main__target:not(.l-nav-main__target--toggle) .l-nav-main__text {
  @include mixin.mq-tbL {
    &::after {
      background: {
        image: url("#{mixin.set_common_path('icon_arrow_nav.svg')}");
        position: center;
        repeat: no-repeat;
        size: contain;
      }
      content: '';
      height: 10px;
      right: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 5px;
    }
  }
}

.l-nav-main-child {
  background-color: variables.$COLOR_WHITE;
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  transition: height 0.3s linear, opacity 0.3s linear, visibility 0.3s linear;
  visibility: hidden;
  width: 100%;

  @include mixin.mq-tbL {
    position: relative;
    &[aria-hidden='false'] {
      height: auto;
      opacity: 1;
      visibility: visible;
    }
  }
}
.l-nav-main-child__inner {
  padding: 20px 10px;

  @include mixin.mq-tbL {
    padding: 0 0 15px 45px;
  }
}
.l-nav-main-child__item {
  @include mixin.fz(14);

  @include mixin.mq-tbL {
    @include mixin.fz(15);
  }
}
.l-nav-main-child__item:nth-child(n + 2) {
  margin-top: 15px;
}
.l-nav-main-child__target {
  text-decoration: none;
}

.l-nav-main__item--accordion {
  &:hover,
  &:focus-within {
    .l-nav-main-child {
      @include mixin.mq-tbL-min {
        height: auto;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.l-nav-sub {
  align-items: center;
  display: flex;
  height: 100px;
  position: absolute;
  right: 0;
  top: 0;

  @include mixin.mq-tbL {
    display: block;
    height: auto;
    padding: 25px 0;
    position: relative;
  }
}

.l-nav-sub__item {
  a {
    text-decoration: none;
  }
}

.l-nav-sub__item:nth-child(n + 2) {
  margin-left: 25px;

  @include mixin.mq-tbL {
    margin-left: 0;
    margin-top: 15px;
  }
}
.l-nav-sub__target {
  align-items: center;
  display: flex;
  text-decoration: none;

  @include mixin.mq-tbL {
    border: 2px solid variables.$COLOR_PINK;
    border-radius: 100px;
    margin: 0 auto;
    padding: 7.5px 15px;
    width: 255px;
  }
}
.l-nav-sub__icon {
  width: 51px;

  @include mixin.mq-tbL {
    width: 44px;
  }
}
.l-nav-sub__text {
  @include mixin.fz(18);
  line-height: 1;
  padding-left: 15px;
  width: calc(100% - 51px);

  @include mixin.mq-tbL {
    @include mixin.fz(16);
    padding-left: 10px;
  }
}
.l-nav-sub__tel-number {
  color: variables.$COLOR_PINK;
  display: block;
  @include mixin.fz(23);
  font-weight: 700;

  @include mixin.mq-tbL {
    @include mixin.fz(20);
  }
}
.l-nav-sub__tel-caption {
  display: block;
  @include mixin.fz(14);
  margin-top: 2px;

  @include mixin.mq-tbL {
    @include mixin.fz(12);
  }
}

.l-nav-toggle {
  appearance: none;
  background: none;
  border: none;
  display: none;
  height: 60px;
  position: fixed;
  right: 0;
  top: 0;
  width: 60px;
  z-index: 11;
  @include mixin.mq-tbL {
    display: block;
  }
}

.l-nav-toggle__bar {
  background: variables.$COLOR_PINK;
  height: 2px;
  left: 18.5px;
  position: absolute;
  top: calc(50% + 5px);
  transition: all 0.3s linear;
  width: 25px;

  &:nth-child(1) {
    margin-top: -10px;
    @include mixin.mq-tbL {
      margin-top: -8px;
    }
    @at-root .l-nav-toggle[aria-expanded='true'] & {
      margin-top: 0;
      transform: rotate(45deg);
    }
  }
  &:nth-child(2) {
    @at-root .l-nav-toggle[aria-expanded='true'] & {
      left: 30px;
      opacity: 0;
    }
  }
  &:nth-child(3) {
    margin-top: 10px;
    @include mixin.mq-tbL {
      margin-top: 8px;
    }
    @at-root .l-nav-toggle[aria-expanded='true'] & {
      margin-top: 0;
      transform: rotate(-45deg);
    }
  }
}
