@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
c-title
*/

.l-header-pageTitle__wrapper {
  margin-bottom: 50px;
  background: {
    image: url('#{mixin.set_image_path('common/bg_title.png')}');
    size: cover;
    repeat: no-repeat;
    position: center;
  }
  @include mixin.mq-tbP {
    background: {
      image: url('#{mixin.set_image_path('common/bg_title_sp.png')}');
      size: cover;
      repeat: no-repeat;
      position: center;
    }
  }
}

.l-header-pageTitle {
  margin: {
    left: auto;
    right: auto;
  }
  position: relative;
  max-width: map_get(variables.$CONTAINER, lg);
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mixin.mq-tbP {
    height: 160px;
  }
  h1 {
    @include mixin.fz(32);
    color: map_get( variables.$BASE, color-page );
  }
}
